import styled from 'styled-components';
import colors from 'styles/colors';

export const MainColumn = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 10px;
  margin: 5px;

  border-radius: 10px;
  background-color: #f6f6f6;

  padding: 10px 15px;
  height: 100%;
  width: 490px;
  max-width: 490px;

  overflow-y: auto;

  ::-webkit-scrollbar-track {
    margin-top: 10px;
    margin-bottom: 10px;
  }

  ::-webkit-scrollbar-thumb {
    background: ${colors.businessPageGrey};
    border: ${colors.businessPageGrey};
  }

  ::-webkit-scrollbar-thumb:hover {
    background: ${colors.businessPageGrey};
    border: ${colors.businessPageGrey};
  }

  overflow-x: hidden;
`;

export const DropCardsContainer = styled.div`
  height: 100%;
  width: 200px;
`;
