import { useState, useMemo, useEffect } from 'react';
import { Checkbox, ClickAwayListener } from '@mui/material';
import DrawerNovaAtividade from '../CreateNewActivity';
import DrawerCancelamentoContrato from '../CancelContract';
import DrawerRenMesmaCia from '../ReadjustmentInsurance';
import DrawerNovoContrato from '../CreateNewContract';
import CardAtividadeDrawer from '../EditActivityInBusinessDetails';
import { MdCheckBox, MdOutlineCheckBoxOutlineBlank } from 'react-icons/md';
import { AiOutlinePlus } from 'react-icons/ai';
import { BsChevronDown, BsChevronUp } from 'react-icons/bs';

import DrawerNovaSeguradora from '../InsertNewInsurance';
import { DrawerNegociosWrapper } from './styles';
import SelectStandard from 'apps/broker/components/Select';
import { HiTrash } from 'react-icons/hi';
import { AiFillCheckCircle } from 'react-icons/ai';
import { toast } from 'react-hot-toast';
import services from 'apps/broker/services';
import Button from 'components/DS/Button';
import InputStandard from 'components/Input/InputStandard';

import {
  BsArrowLeftCircleFill,
  BsArrowRightCircleFill,
  BsCheck,
  BsCircle,
  BsCircleFill,
  BsFillCheckCircleFill
} from 'react-icons/bs';
import './BusinessDetails.scss';

const estagiosOptions = [
  { option: 'Estudo', value: 1 },
  { option: 'Não alocado', value: 5 },
  { option: 'Negociação', value: 2 },
  { option: 'Proposta em análise da seguradora', value: 3 },
  { option: 'Implantação', value: 4 }
];
const DrawerNegocios = ({
  formatDate,
  isSelected,
  getNegociosData,
  iconAtividadeHandler,
  corretores,
  setIsNewResp
}) => {
  const [seguradoras, setSeguradoras] = useState([]);
  const [defaultObservacoes, setDefaultObservacoes] = useState('');
  const [editedObservacoes, setEditObservacoes] = useState('');
  const [showSaveButton, setShowSaveButton] = useState(false);
  const [isNewActivityDrawer, setIsNewActivityDrawer] = useState(undefined);
  const [isNewSeguradoraDrawer, setIsNewSeguradoraDrawer] = useState(undefined);
  const [cancelamentoDrawer, setCancelamentoDrawer] = useState(false);
  const [renMesmaCia, setRenMesmaCiaDrawer] = useState(false);
  const [novoContratoDrawer, setNovoContratoDrawer] = useState(false);
  const [editAtividade, setEditAtividade] = useState(0);
  const [vidas, setVidas] = useState(isSelected?.vidas);
  const [showAtividadesRelacionadas, setShowAtividadesRelacionadas] =
    useState(true);
  const [showAtividadesConcluidas, setShowAtividadesConcluidas] =
    useState(true);
  const [showSeguradoras, setShowSeguradoras] = useState(true);

  const toggleAtividadesRelacionadas = () =>
    setShowAtividadesRelacionadas(!showAtividadesRelacionadas);
  const toggleAtividadesConcluidas = () =>
    setShowAtividadesConcluidas(!showAtividadesConcluidas);
  const toggleSeguradoras = () => setShowSeguradoras(!showSeguradoras);

  const getInsuranceCompanies = async () => {
    const response = await services.insuranceCompanies.getAll();
    setSeguradoras(response.data);
  };

  const getOptionsCorretores = () => {
    const naoAlocado = {
      value: '0',
      option: 'Selecione'
    };
    const responsaveis = corretores?.map((item) => ({
      value: item.usuario_id,
      option: `${item.nome} ${item.sobrenome}`
    }));

    return [naoAlocado, ...responsaveis];
  };

  const optionsCorretores = () => {
    const naoAlocado = {
      value: '0',
      option: 'Selecione'
    };
    const responsaveis = corretores?.map((item) => ({
      value: item.usuario_id,
      option: `${item.nome} ${item.sobrenome}`
    }));

    const optionsCorretores = [naoAlocado, ...responsaveis];
    return optionsCorretores?.map((item) => (
      <option
        className="drawer-negocios__options"
        key={`corretor-${item.value}`}
        value={item.value}
      >
        {`${item.option}`}
      </option>
    ));
  };

  const orderSeguradorasInfo = () => {
    const seguradoras = isSelected?.seguradoras?.sort((a, b) => {
      if (b.seguradora_nome < a.seguradora_nome) {
        return 1;
      }
      return -1;
    });

    return seguradoras;
  };

  const orderAtividades = () => {
    const atividades = isSelected?.atividades?.sort((a, b) => {
      const dataA = new Date(a.data_agendada);
      const dataB = new Date(b.data_agendada);

      if (dataA < dataB) {
        return -1;
      }

      return 1;
    });

    return atividades;
  };

  const renderOptionSeguradora = () => {
    const status = [
      { option: '  ', value: null },
      { option: 'Em cotação', value: 1 },
      { option: 'Cotação apresentada', value: 2 },
      { option: 'Risco negado', value: 3 }
    ];

    return status;
  };

  const renderOptionEstagio = () => {
    const estagio = [
      { estagio: 'Estudo', value: 1 },
      { estagio: 'Não alocado', value: 5 },
      { estagio: 'Negociação', value: 2 },
      { estagio: 'Proposta em análise da seguradora', value: 3 },
      { estagio: 'Implantação', value: 4 }
    ];

    return estagio?.map((item) => (
      <option key={`estagio-${item.value}`} value={item.value}>
        {item.estagio}
      </option>
    ));
  };

  const renderColorAtivity = (item) => {
    let color;

    if (item.data_fechamento === null) {
      color = iconAtividadeHandler(item);
    } else {
      color = 'yellow';
    }

    return color;
  };

  const onUpdateLifes = async (newLifes, negocio) => {
    const body = {
      negocio_id: negocio.id,
      newData: {
        vidas: Number(newLifes)
      }
    };

    const response = await services.business.updateResponsableByBusinessId(
      body
    );

    if (response.status === 200) {
      getNegociosData();
      toast.remove();
      toast.success(`Número de vidas foram alterado com sucesso!`, {
        duration: 2500
      });
    } else {
      toast.remove();
      toast.error('Algo deu errado, tente novamente mais tarde!', {
        duration: 2500
      });
    }
  };

  const onChangeResponsavelNegocio = async (value, negocio) => {
    const integerId = Number(value);
    const body = {
      negocio_id: negocio.id,
      newData: {
        usuario_id: integerId ? integerId : null
      }
    };

    const response = await services.business.updateResponsableByBusinessId(
      body
    );
    if (response.status === 200) {
      getNegociosData();
      setIsNewResp({ usuario_id: integerId, negocio });
      toast.remove();
      toast.success(`O usuário responsável pelo negócio foi atualizado!`, {
        duration: 2500
      });
    } else {
      toast.remove();
      toast.error('Algo deu errado, tente novamente mais tarde!', {
        duration: 2500
      });
    }
  };

  const changeSeguradoraStatusHandler = async (item, value, comment) => {
    const body = {
      seguradoraItem: item,
      newData: {
        status_id: value ? value : null,
        comentario: comment
      }
    };
    await services.business.updateInsuranceStatusByInsuranceId(body);

    getNegociosData();
  };

  const atividadeStatusHandler = async (atividade_id, done) => {
    await services.activities.updateStatusClosedByActivityId({
      atividade_id,
      newData: {
        closed_at: done ? new Date() : null
      }
    });

    console.log('loading again');
    getNegociosData();

    if (done) {
      toast.success('Atividade concluída com sucesso');
    } else {
      toast.success('Atividade reaberta com sucesso');
    }
  };

  const deleteAtividadeClickHandler = async (item) => {
    const response = await services.activities.deleteActivity(item);
    if (response.status === 200) {
      getNegociosData();

      toast.dismiss();
      toast.success('Atividade removida com sucesso');
    }
  };

  const updateObservacaoClickHandler = async () => {
    const body = {
      negocio_id: isSelected?.id,
      newData: {
        observacoes: editedObservacoes
      }
    };

    const response = await services.business.updateDescriptionByBusinessId(
      body
    );
    if (response.status === 200) {
      toast.dismiss();
      toast.success('Observação atualizada com sucesso');
      getNegociosData();
    }
  };

  const changeTextAreaHandler = (e) => {
    setEditObservacoes(e.target.value);
  };

  const updateEstagioOnChange = async (value) => {
    const body = {
      negocio_id: isSelected?.id,
      estagio_id: value
    };

    const response = await services.business.updateStageByBusinessId(body);
    if (response.status === 200) {
      toast.dismiss();
      toast.success('Estágio atualizado com sucesso');
      getNegociosData();
    } else {
      toast.dismiss();
      toast.error('Algo deu errado, tente novamente mais tarde!');
    }
  };

  const updateResponsavelAtividade = async (atividade_id, usuario_id) => {
    const body = {
      atividade_id: atividade_id,
      newData: {
        usuario_id: usuario_id !== '0' ? usuario_id : null
      }
    };

    const response = await services.activities.updateResponsableByActivityId(
      body
    );
    if (response.status === 200) {
      toast.dismiss();
      toast.success(`O usuário responsável pela atividade foi atualizado!`, {
        duration: 2500
      });
      getNegociosData();
    } else {
      toast.dismiss();
      toast.error('Algo deu errado, tente novamente mais tarde!', {
        duration: 2500
      });
    }
  };

  const atividadeResponsavelChangeHandler = (e, item) => {
    const responsavelId = e.target.value;
    const atividadeId = item.atividade_id;

    updateResponsavelAtividade(atividadeId, responsavelId);
  };

  const changeAtividadesInfo = (atividade_id) => {
    setEditAtividade(atividade_id);
  };

  const closeAtividadesInfo = () => {
    setEditAtividade(0);
  };

  const renderRazaoSocial = () => {
    if (isSelected?.estipulante_razao_social) {
      return isSelected?.estipulante_razao_social;
    } else {
      return isSelected?.lead_razao_social;
    }
  };

  const renderCnpj = () => {
    if (isSelected?.estipulante_id) {
      return isSelected?.estipulante_cnpj;
    } else {
      return isSelected?.lead_potencial_cnpj;
    }
  };

  const renderNomeFantasia = () => {
    if (isSelected?.estipulante_id) {
      if (isSelected?.estipulante_nome_fantasia !== null) {
        if (isSelected?.estipulante_nome_fantasia?.nome_fantasia !== null) {
          return isSelected?.estipulante_nome_fantasia?.nome_fantasia;
        } else {
          return '';
        }
      } else {
        return '';
      }
    } else if (isSelected?.lead_potencial_id) {
      if (isSelected?.lead_potencial_nome_fantasia !== null) {
        if (isSelected?.lead_potencial_nome_fantasia?.nome_fantasia !== null) {
          return isSelected?.lead_potencial_nome_fantasia?.nome_fantasia;
        } else {
          return '';
        }
      } else {
        return '';
      }
    }
  };

  useMemo(() => {
    setEditObservacoes(defaultObservacoes);
  }, [defaultObservacoes]);

  useMemo(() => {
    if (Boolean(isNewActivityDrawer)) {
      setIsNewActivityDrawer(isSelected);
    }

    // Esta condição abrirá um drawer de nova atividade
    if (Boolean(isNewSeguradoraDrawer)) {
      setIsNewSeguradoraDrawer(isSelected);
    }

    setDefaultObservacoes(isSelected?.observacoes);
  }, [isSelected]);

  useEffect(() => {
    getInsuranceCompanies();
  }, []);

  useEffect(() => {
    if (editedObservacoes !== defaultObservacoes) {
      setShowSaveButton(true);
    } else {
      setShowSaveButton(false);
    }
  }, [editedObservacoes, defaultObservacoes, cancelamentoDrawer]);

  console.log('isSelected?.atividades', isSelected?.atividades);

  return (
    <DrawerNegociosWrapper>
      <div className="drawer-negocios__header">
        <div className="drawer-negocios__title">{`${renderRazaoSocial()} - ${renderCnpj()}`}</div>
        <div className="drawer-negocios__title">{`Nome fantasia: ${renderNomeFantasia()}`}</div>
        <div className="drawer-negocios__subtitle">
          {isSelected?.tipo === 'Novo' ? (
            <span>Novo</span>
          ) : (
            <span>Renovação</span>
          )}
        </div>
        {isSelected?.tipo === 'Renovação' && isSelected?.seguradora_nome ? (
          isSelected?.numero_apolice ? (
            <div className="drawer-negocios__subtitle drawer-negocios__seguradora-nome">{`${isSelected?.produto} - ${isSelected?.seguradora_nome} - Apólice ${isSelected?.numero_apolice}`}</div>
          ) : (
            <div className="drawer-negocios__subtitle drawer-negocios__seguradora-nome">{`${isSelected?.produto} - ${isSelected?.seguradora_nome}`}</div>
          )
        ) : (
          ''
        )}
      </div>
      <div className="drawer-negocios__content">
        <div className="drawer-negocios__status">
          {isSelected?.tipo === 'Novo' ? (
            <>
              <Button
                size={'small'}
                variant="default"
                className="drawer-negocios__status--verde"
                onClick={() => setNovoContratoDrawer(true)}
              >
                Negócio Concluído
              </Button>
              <Button
                size={'small'}
                variant="default"
                className="drawer-negocios__status--vermelho"
                onClick={() => setCancelamentoDrawer(true)}
              >
                Perdemos o cliente
              </Button>
            </>
          ) : (
            <>
              <Button
                size={'small'}
                variant="default"
                className="drawer-negocios__status--verde"
                onClick={() => setRenMesmaCiaDrawer(true)}
              >
                Renovou na mesma cia.
              </Button>
              <Button
                size={'small'}
                variant="default"
                className="drawer-negocios__status--cinza"
                onClick={() => setNovoContratoDrawer(true)}
              >
                Renovou em outra cia.
              </Button>
              <Button
                variant="default"
                size={'small'}
                className="drawer-negocios__status--vermelho"
                onClick={() => setCancelamentoDrawer(true)}
              >
                Perdemos o cliente
              </Button>
            </>
          )}
        </div>
        <div className="drawer-negocios__linha-primeira">
          <div className="drawer-negocios__coluna-primeira">
            <div className="drawer-negocios__info-column">
              <SelectStandard
                name="estagio"
                value={Number(isSelected?.estagio_id)}
                label="Estágio"
                options={estagiosOptions}
                setValue={({ value }) => updateEstagioOnChange(value)}
              />
            </div>
            <div className="drawer-negocios__info-column">
              <SelectStandard
                name="responsavel"
                value={Number(isSelected?.usuario_id)}
                label="Responsável"
                options={getOptionsCorretores()}
                setValue={({ value }) =>
                  onChangeResponsavelNegocio(value, isSelected)
                }
              />
            </div>
            <div className="drawer-negocios__info-column">
              <InputStandard
                name="vidas"
                label="Vidas"
                value={vidas}
                setValue={(e) => setVidas(e.target.value)}
                onBlur={() => onUpdateLifes(vidas, isSelected)}
              />
            </div>
            <div className="drawer-negocios__info-column">
              <span className="drawer-negocios__span-header">Produto</span>
              <span>{isSelected?.produto}</span>
            </div>
          </div>
        </div>
        <div className="drawer-negocios__linha-segunda">
          <div
            className={`drawer-negocios__atividades-header ${
              isSelected?.tipo === 'Novo'
                ? 'drawer-negocios__atividades-header--p-top'
                : ''
            }`}
          >
            <span
              className="drawer-negocios__atividades-header-title"
              onClick={toggleAtividadesRelacionadas}
            >
              Atividades
              {showAtividadesRelacionadas ? (
                <BsChevronUp size={14} />
              ) : (
                <BsChevronDown size={14} />
              )}
            </span>

            <span
              className="drawer-negocios__atividades-header-more"
              onClick={() => setIsNewActivityDrawer(isSelected)}
            >
              <AiOutlinePlus size={18} style={{ cursor: 'pointer' }} />
            </span>
          </div>
          {showAtividadesRelacionadas && (
            <div className="drawer-negocios__atividades-table">
              <div className="drawer-negocios__atividades-table-header">
                <p>Concluir</p>
                <p>Atividade</p>
                <p>Data</p>
                <p>Responsável</p>
                <p>Ações</p>
              </div>
              <div className="drawer-negocios__lista-atividades">
                {orderAtividades()?.map((item) => (
                  <CardAtividadeDrawer
                    key={`atividade-relacionada-${item.atividade_id}`}
                    item={item}
                    changeAtividadesInfo={changeAtividadesInfo}
                    editAtividade={editAtividade}
                    closeAtividadesInfo={closeAtividadesInfo}
                    renderColorAtivity={renderColorAtivity}
                    formatDate={formatDate}
                    atividadeResponsavelChangeHandler={
                      atividadeResponsavelChangeHandler
                    }
                    optionsCorretores={optionsCorretores}
                    atividadeStatusHandler={atividadeStatusHandler}
                    deleteAtividadeClickHandler={deleteAtividadeClickHandler}
                    getNegociosData={getNegociosData}
                  />
                ))}
              </div>
            </div>
          )}
          {/* <div className="drawer-negocios__atividades-header"> */}
          {/*   <span */}
          {/*     className="drawer-negocios__atividades-header-title" */}
          {/*     onClick={toggleAtividadesConcluidas} */}
          {/*   > */}
          {/*     Atividades Concluídas */}
          {/*     {showAtividadesConcluidas ? ( */}
          {/*       <BsChevronUp size={14} /> */}
          {/*     ) : ( */}
          {/*       <BsChevronDown size={14} /> */}
          {/*     )} */}
          {/*   </span> */}
          {/*   <span */}
          {/*     className="drawer-negocios__atividades-header-more" */}
          {/*     onClick={() => setIsNewActivityDrawer(isSelected)} */}
          {/*   > */}
          {/*     <AiOutlinePlus size={18} style={{ cursor: 'pointer' }} /> */}
          {/*   </span> */}
          {/* </div> */}
          {/* {showAtividadesConcluidas && ( */}
          {/*   <div className="drawer-negocios__atividades-table"> */}
          {/*     <div className="drawer-negocios__atividades-table-header"> */}
          {/*       <p>Concluir</p> */}
          {/*       <p>Atividade</p> */}
          {/*       <p>Data</p> */}
          {/*       <p>Responsável</p> */}
          {/*       <p>Ações</p> */}
          {/*     </div> */}
          {/*     <div className="drawer-negocios__lista-atividades"> */}
          {/*       {isSelected?.atividades?.map((item) => ( */}
          {/*         <div */}
          {/*           className="drawer-negocios__atividades-coluna-segunda" */}
          {/*           key={`${item.negocio_id}-${item.atividade_id}`} */}
          {/*         > */}
          {/*           <div className="drawer-negocios__check-concluidas"> */}
          {/*             <Checkbox */}
          {/*               style={{ color: '#5bb347' }} */}
          {/*               size={'large'} */}
          {/*               checked={item.data_fechamento ? true : false} */}
          {/*               icon={<MdOutlineCheckBoxOutlineBlank size={24} />} */}
          {/*               checkedIcon={<MdCheckBox size={24} />} */}
          {/*             /> */}
          {/*           </div> */}
          {/*           <span style={{ marginLeft: 10 }}>{item.descricao}</span> */}
          {/*           <span>{formatDate(item.data_agendada)}</span> */}
          {/*           <span> */}
          {/*             {item.usuario_id */}
          {/*               ? `${item.usuario_nome} ${item.usuario_sobrenome}` */}
          {/*               : ''} */}
          {/*           </span> */}
          {/*           <div> */}
          {/*             <button */}
          {/*               className="drawer-negocios__trash" */}
          {/*               onClick={() => deleteAtividadeClickHandler(item)} */}
          {/*             > */}
          {/*               <HiTrash /> */}
          {/*             </button> */}
          {/*           </div> */}
          {/*         </div> */}
          {/*       ))} */}
          {/*     </div> */}
          {/*   </div> */}
          {/* )} */}
        </div>
        <div className="drawer-negocios__linha-terceira">
          <div className="drawer-negocios__atividades-header">
            <span
              className="drawer-negocios__atividades-header-title"
              onClick={toggleSeguradoras}
            >
              Seguradoras
              {showSeguradoras ? (
                <BsChevronUp size={14} />
              ) : (
                <BsChevronDown size={14} />
              )}
            </span>

            <span
              className="drawer-negocios__atividades-header-more"
              onClick={() => setIsNewSeguradoraDrawer(isSelected)}
            >
              <AiOutlinePlus size={18} style={{ cursor: 'pointer' }} />
            </span>
          </div>
          {showSeguradoras && (
            <div className="drawer-negocios__lista-seguradoras">
              {orderSeguradorasInfo()?.map((item, i) => (
                <div
                  className="drawer-negocios__lista-seguradoras__option"
                  key={`seguradora-${item?.id}`}
                >
                  <div>{item?.seguradora_nome}</div>
                  <div>
                    <SelectStandard
                      label="Status"
                      name="status"
                      defaultValue={item?.status_id}
                      value={item?.status_id}
                      options={renderOptionSeguradora()}
                      setValue={({ value }) =>
                        changeSeguradoraStatusHandler(item, value)
                      }
                    />
                  </div>

                  <div>
                    <InputStandard
                      label="Comentário"
                      value={item?.comentario}
                      setValue={(e) => {
                        changeSeguradoraStatusHandler(
                          item,
                          item?.status_id,
                          e.target.value
                        );
                      }}
                    />
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>
        <div className="drawer-negocios__linha-quarta">
          <div className="drawer-negocios__atividades-header">Observação</div>
          <textarea
            value={editedObservacoes}
            onChange={changeTextAreaHandler}
            rows={10}
            maxLength={1200}
          />
          <div className="drawer-negocios__button-container">
            {showSaveButton ? (
              <button
                className="drawer-negocios__save-observacoes-btn"
                onClick={updateObservacaoClickHandler}
              >
                Salvar
              </button>
            ) : (
              ''
            )}
          </div>
        </div>
      </div>
      {isNewActivityDrawer && (
        <DrawerNovaAtividade
          setNewActivity={setIsNewActivityDrawer}
          isNewActivity={isNewActivityDrawer}
          corretores={corretores}
          getNegociosData={getNegociosData}
          setNewAtividadeNegocio={setIsNewActivityDrawer}
        />
      )}
      {isNewSeguradoraDrawer && (
        <DrawerNovaSeguradora
          setNewActivity={setIsNewSeguradoraDrawer}
          isNewActivity={isNewSeguradoraDrawer}
          seguradoras={seguradoras}
          getNegociosData={getNegociosData}
        />
      )}
      {cancelamentoDrawer && (
        <DrawerCancelamentoContrato
          setCancelamentoDrawer={setCancelamentoDrawer}
          isSelected={isSelected}
          getNegociosData={getNegociosData}
        />
      )}
      {novoContratoDrawer && (
        <DrawerNovoContrato
          setNovoContratoDrawer={setNovoContratoDrawer}
          isSelected={isSelected}
          getNegociosData={getNegociosData}
          seguradoras={seguradoras}
        />
      )}
      {renMesmaCia && (
        <DrawerRenMesmaCia
          setRenMesmaCiaDrawer={setRenMesmaCiaDrawer}
          isSelected={isSelected}
          getNegociosData={getNegociosData}
        />
      )}
    </DrawerNegociosWrapper>
  );
};

export default DrawerNegocios;
