import { Switch, Route } from 'react-router-dom';
import PrivateRoute from './PrivateRoute';

import ZecaHome from 'apps/lp/pages/ZecaHome';
import Login from 'apps/lp/pages/Login';
import Signup from 'apps/lp/pages/Signup';
import RecuperarSenha from 'apps/lp/pages/RecuperarSenha';
import AlterarSenha from 'apps/lp/pages/RecuperarSenha/alterarSenha';
import ConfirmationAccount from 'apps/lp/pages/Signup/confirmationAccount';
import Questionario from 'apps/lp/pages/Questionario/index';
import QuestionarioPlanosBloqueados from 'apps/lp/pages/Questionario/QuestionarioPlanosBloqueados';
import ResultFormTicket from 'apps/lp/pages/Questionario/ResultFormTicket';
import CadastroEstipulante from 'apps/lp/pages/Cadastro-estipulante';
import InclusionForm from 'apps/lp/pages/InclusionForm';

import CadastroCorretor from 'apps/broker/pages/Register';
import ContatosSeguradoras from 'apps/broker/pages/InsuranceCampaniesContacts';
import LoginCorretor from 'apps/broker/pages/Login';
import Concierge from 'apps/broker/pages/Concierge';
import Relatorios from 'apps/broker/pages/Reports';
import RecuperarSenhaCorretora from 'apps/broker/pages/ResetPassword';
import Negocios from 'apps/broker/pages/Business';
import NewBusiness from 'apps/broker/pages/NewBusiness';
import Tasks from 'apps/broker/pages/Tasks';
import GestaoTicketsCorretor from 'apps/broker/pages/TicketsManage';
import BeneficiariosCorretor from 'apps/broker/pages/Beneficiaries';
import GestaoBeneficiarios from 'apps/broker/pages/GestaoBeneficiarios';
import HomeCorretor from 'apps/broker/pages/BrokerHome';
import PolicyOwners from 'apps/broker/pages/PolicyOwners';
import PolicyOwnerContracts from 'apps/broker/pages/PolicyOwnerContracts';

import CadastroRh from 'apps/hr/pages/CadastroRh';
import ConfirmationAccountRh from 'apps/hr/pages/CadastroRh/confirmationAccount';
import GestaoBeneficiariosRh from 'apps/hr/pages/GestaoBeneficiarios';
import GestaoUsuarios from 'apps/hr/pages/GestaoUsuarios';
import TrocarSenha from 'apps/hr/pages/TrocarSenha';
import LoginRh from 'apps/hr/pages/Login';
import RecuperarSenhaRh from 'apps/hr/pages/RecuperarSenha';
import HomeRH from 'apps/hr/pages/HomeRH';
import HomeRh from 'apps/hr/pages/Home';
import DadosEstipulanteRH from 'apps/hr/pages/DadosEstipulante';
import DadosCadastrais from 'apps/hr/pages/DadosCadastrais';
import Contratos from 'apps/hr/pages/Contratos';
import Beneficiarios from 'apps/hr/pages/Beneficiarios';
import GestaoTickets from 'apps/hr/pages/GestaoTickets';
import Tickets from 'apps/hr/pages/Tickets';
import ManagerRequestInclusion from 'apps/hr/pages/ManagerRequestInclusion';

const Routes = () => (
  <Switch>
    {/* Landing page */}
    <Route exact path="/" component={ZecaHome} />
    <Route path="/login" component={Login} />
    <Route path="/cadastro" component={Signup} />
    <Route path="/recuperar-senha" component={RecuperarSenha} />
    <Route path="/confirma-conta/:id/:token" component={ConfirmationAccount} />
    <Route
      path="/alterar-senha/:tipoUsuario/:id/:token"
      component={AlterarSenha}
    />
    <Route
      path="/questionario-inclusao/:corretoraId/:estipulanteId/:contratoId"
      component={Questionario}
    />
    <Route
      path="/questionario-inclusao-block/:corretoraId/:estipulanteId/:contratoId"
      component={QuestionarioPlanosBloqueados}
    />
    <Route
      path="/questionario-inclusao-ticket/:corretoraId/:estipulanteId/:contratoId"
      component={ResultFormTicket}
    />
    <Route path="/formulario-inclusao-plano-saude" component={InclusionForm} />

    <PrivateRoute
      path="/cadastro-estipulante"
      component={CadastroEstipulante}
    />

    {/* Broker */}
    <Route path="/corretor/login" component={LoginCorretor} />
    <Route
      path="/corretor/recuperar-senha"
      component={RecuperarSenhaCorretora}
    />
    <Route
      path="/corretor/register-corretor/:token"
      component={CadastroCorretor}
    />
    <PrivateRoute path="/corretor/home" component={HomeCorretor} />
    <PrivateRoute
      path="/corretor/estipulantes/:estipulante_id"
      component={PolicyOwnerContracts}
    />
    <PrivateRoute path="/corretor/estipulantes" component={PolicyOwners} />
    <PrivateRoute
      path="/corretor/gestao-tickets"
      component={GestaoTicketsCorretor}
    />
    <PrivateRoute path="/corretor/concierge" component={Concierge} />
    <PrivateRoute path="/corretor/relatorios" component={Relatorios} />
    <PrivateRoute path="/corretor/negocios" component={Negocios} />
    <PrivateRoute path="/corretor/novo-negocios" component={NewBusiness} />
    <PrivateRoute path="/corretor/tasks" component={Tasks} />
    <PrivateRoute
      path="/corretor/beneficiarios"
      component={BeneficiariosCorretor}
    />
    <PrivateRoute
      path="/corretor/movimentacao-beneficiarios"
      component={GestaoBeneficiarios}
    />
    <PrivateRoute
      path="/corretor/contatos-seguradoras"
      component={ContatosSeguradoras}
    />

    {/* HR */}
    <Route path="/rh/login" component={LoginRh} />
    <Route path="/rh/recuperar-senha" component={RecuperarSenhaRh} />
    <Route path="/cadastro-rh/:token" component={CadastroRh} />
    <Route
      path="/confirma-conta-rh/:id/:token"
      component={ConfirmationAccountRh}
    />
    <PrivateRoute path="/rh/homerh" component={HomeRh} />
    <PrivateRoute path="/rh/estipulante" component={DadosEstipulanteRH} />
    <PrivateRoute path="/rh/home" component={HomeRH} />
    <PrivateRoute path="/rh/dados-cadastrais" component={DadosCadastrais} />
    <PrivateRoute path="/rh/contratos" component={Contratos} />
    <PrivateRoute
      path="/rh/gestao-solicitacao-inclusao"
      component={ManagerRequestInclusion}
    />
    <PrivateRoute path="/rh/tickets" component={Tickets} />
    <PrivateRoute
      path="/rh/movimentacao-beneficiarios"
      component={GestaoBeneficiariosRh}
    />
    <PrivateRoute path="/rh/gestao-tickets" component={GestaoTickets} />
    <PrivateRoute path="/rh/beneficiarios" component={Beneficiarios} />
    <PrivateRoute path="/rh/usuarios" component={GestaoUsuarios} />
    <PrivateRoute path="/rh/trocar-senha" component={TrocarSenha} />
  </Switch>
);

export default Routes;
