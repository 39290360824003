import DatePicker from 'react-datepicker';
import Button from 'components/DS/Button';
import SelectStandard from 'apps/broker/components/Select';
import CheckmarkStandard from '../../../../components/Select/Checkmark';
import { Radio, ThemeProvider, createTheme } from '@mui/material';
import './FilterAtividades.scss';

const theme = createTheme({
  palette: {
    genoaBlue: {
      main: '#456AED'
    },
    darkGenoaBlue: {
      main: '#1E2552'
    }
  }
});

const FilterAtividades = ({
  corretores,
  vencimento,
  responsavel,
  setVencimento,
  setResponsavel,
  setDataInicial,
  dataInicial,
  setDataFinal,
  dataFinal,
  resetActivitiesFilters,
  setOpenDrawer,
  setFilter,
  setNewActivity
}) => {
  const selectResponsavelChangeHandler = (selected) => {
    setResponsavel(selected.value);
    resetActivitiesFilters();
  };

  return (
    <div className="filter-atividades">
      <div className="filter-atividades__section">
        <Button
          className="filter-atividades__action"
          variant="secondary"
          onClick={() => setNewActivity(true)}
        >
          + Atividade
        </Button>

        <SelectStandard
          value={responsavel}
          setValue={selectResponsavelChangeHandler}
          options={corretores.map((item) => ({
            value: item?.usuario_id,
            option: `${item.nome} ${item.sobrenome}`
          }))}
          label={'Responsável'}
          selectPlaceHolder="Selecione"
        />

        <div className="filter-atividades__options">
          <ThemeProvider theme={theme}>
            <p className="filter-atividades__section-label">Entrega</p>
            <div className="filter-atividades__radio-group">
              <div className="filter-atividades__radio-container">
                <Radio
                  onClick={() => setVencimento('vencidas')}
                  color={'darkGenoaBlue'}
                  checked={vencimento === 'vencidas'}
                />
                <label>Vencidas</label>
              </div>
              <div className="filter-atividades__radio-container">
                <Radio
                  onClick={() => setVencimento('hoje')}
                  color={'darkGenoaBlue'}
                  checked={vencimento === 'hoje'}
                />
                <label>Hoje</label>
              </div>
              <div className="filter-atividades__radio-container">
                <Radio
                  onClick={() => setVencimento('amanha')}
                  color={'darkGenoaBlue'}
                  checked={vencimento === 'amanha'}
                />
                <label>Amanhã</label>
              </div>

              <div className="filter-atividades__radio-container">
                <Radio
                  onClick={() => setVencimento('em_aberto')}
                  color={'darkGenoaBlue'}
                  checked={vencimento === 'em_aberto'}
                />
                <label>A fazer</label>
              </div>

              <div className="filter-atividades__radio-container">
                <Radio
                  onClick={() => setVencimento('done')}
                  color={'darkGenoaBlue'}
                  checked={vencimento === 'done'}
                />
                <label>Concluídas</label>
              </div>
              {/* <div className="filter-atividades__radio-container"> */}
              {/*   <Radio */}
              {/*     onClick={() => setVencimento('periodo')} */}
              {/*     color={'darkGenoaBlue'} */}
              {/*     checked={vencimento === 'periodo'} */}
              {/*   /> */}
              {/*   <label>Período</label> */}
              {/*   {vencimento === 'periodo' && ( */}
              {/*     <div className="filter-atividades__datepicker-group"> */}
              {/*       <div className="filter-atividades__datepicker-container"> */}
              {/*         <span>Data Inicial:</span> */}
              {/*         <DatePicker */}
              {/*           className="filter-atividades__input-date" */}
              {/*           locale="ptBR" */}
              {/*           selected={dataInicial} */}
              {/*           onChange={(date) => { */}
              {/*             setDataInicial(date); */}
              {/*             if (!dataFinal) { */}
              {/*               setDataFinal(date); */}
              {/*             } */}
              {/*           }} */}
              {/*           placeholderText="..." */}
              {/*           dateFormat="dd/MM/yyyy" */}
              {/*         /> */}
              {/*       </div> */}
              {/*       <div className="filter-atividades__datepicker-container"> */}
              {/*         <span>Data Final:</span> */}
              {/*         <DatePicker */}
              {/*           className="filter-atividades__input-date" */}
              {/*           disabled={dataInicial === ''} */}
              {/*           locale="ptBR" */}
              {/*           selected={dataFinal} */}
              {/*           onChange={(date) => setDataFinal(date)} */}
              {/*           placeholderText="..." */}
              {/*           dateFormat="dd/MM/yyyy" */}
              {/*         /> */}
              {/*       </div> */}
              {/*     </div> */}
              {/*   )} */}
              {/* </div> */}
            </div>
          </ThemeProvider>
        </div>
      </div>
    </div>
  );
};

export default FilterAtividades;
