import { useEffect, useState } from 'react';
import SidemenuRh from 'apps/hr/components/Sidebar-RH';
import Header from 'apps/hr/components/Header-RH-Novo';
import CheckmarkStandard from 'apps/broker/components/Select/Checkmark';
import TableHeader from './TableHeader';
import TableResult from './TableResult';
import { useInclusionRequests } from 'contexts/inclusionRequests';
import { useUser } from 'contexts/user';
import DownloadPersonalInformation from './DownloadPersonalInformation';
import service from 'apps/hr/services';
import './style.scss';

const ManagerRequestInclusion = () => {
  const { user } = useUser();
  const {
    holdersInformation,
    statusRequests,
    status,
    setStatus,
    getInclusionRequests
  } = useInclusionRequests();

  const [personalInformation, setPersonalInformation] = useState([]);
  const [masterCheckboxChecked, setMasterCheckboxChecked] = useState(false);
  const [selectedCheckboxes, setSelectedCheckboxes] = useState([]);
  const [editMode, setEditMode] = useState(false);
  const [anchorElDelete, setAnchorElDeletePopover] = useState(null);

  const handleClose = () => {
    setAnchorElDeletePopover(null);
  };

  const editModeClickHandler = () => {
    setEditMode((prevState) => !prevState);
  };

  const clickMasterCheckboxHandler = ({ target }) => {
    const checked = target.checked;

    if (checked) {
      const allIdFromHolders = holdersInformation.map(
        (holder) => holder.beneficiario_id
      );
      setSelectedCheckboxes(allIdFromHolders);
      setMasterCheckboxChecked((prevstate) => !prevstate);
    }

    if (!checked) {
      setSelectedCheckboxes([]);
      setMasterCheckboxChecked((prevstate) => !prevstate);
    }
  };

  const popupPositiveClickHandler = async () => {
    await service.inclusionRequest.deleteRequestInclusion(
      selectedCheckboxes
    );
    // console.log('selected', selectedCheckboxes);
    setAnchorElDeletePopover(null);
    setEditMode(false);
    getInclusionRequests();
  };

  const deleteDrawerToggleHandler = (target) => {
    setAnchorElDeletePopover((prevState) => (prevState ? null : target));
  };

  const handleDisplayMessage = () => {
    if (selectedCheckboxes.length > 0) {
      if (selectedCheckboxes.length === 1) {
        return 'Você quer realmente excluir o item selecionado?';
      }

      return 'Você quer realmente excluir os itens selecionados?';
    }
    return 'Selecione pelo menos um item para excluir';
  };

  const popupNegativeClickHandler = () => {
    setAnchorElDeletePopover(null);
  };

  const clickDeleteRequestInclusionHandler = ({ target }) => {
    deleteDrawerToggleHandler(target);
  };

  const clickUniqueCheckboxHandler = ({ target }) => {
    const checked = target.checked;
    const requestId = Number(target.name);

    if (checked) {
      setSelectedCheckboxes((prevState) => [...prevState, requestId]);
    }

    if (!checked) {
      setSelectedCheckboxes((prevState) => {
        const filter = prevState.filter(
          (elementName) => elementName !== requestId
        );
        return filter;
      });
    }
  };

  const getPesonalInformationToDownload = async () => {
    const titulares = await service.inclusionRequest.getPersonalInformation(
      user.estipulante
    );

    if (titulares.length > 0) {
      return;
    }

    setPersonalInformation(titulares);
  };

  useEffect(() => {
    getInclusionRequests();
    getPesonalInformationToDownload();
  }, []);

  return (
    <div className="manager-request">
      <SidemenuRh />
      <main className="manager-request__main">
        <Header />
        <div className="manager-request-container">
          <div
            className="manager-request-content-title"
            style={{ marginBottom: 30 }}
          >
            <h1 className="manager-request-content-title__title">
              Todas as solicitações de inclusão
            </h1>
          </div>
          <div className="manager-request-content">
            <div className="manager-request-content__checkbox-content">
              <CheckmarkStandard
                value={status}
                setValue={setStatus}
                options={statusRequests}
                label="Status de solicitação"
                variant="hr"
              />
            </div>
            <div className="manager-request-content__button-content">
              <DownloadPersonalInformation
                personalInformation={personalInformation}
              />
            </div>
          </div>
          <div>
            <TableHeader
              editMode={editMode}
              anchorElDelete={anchorElDelete}
              holdersInformation={holdersInformation}
              editModeClickHandler={editModeClickHandler}
              clickMasterCheckboxHandler={clickMasterCheckboxHandler}
              selectedCheckboxes={selectedCheckboxes}
              masterCheckboxChecked={masterCheckboxChecked}
              clickDeleteRequestInclusionHandler={
                clickDeleteRequestInclusionHandler
              }
              handleClose={handleClose}
              handleDisplayMessage={handleDisplayMessage}
              popupPositiveClickHandler={popupPositiveClickHandler}
              popupNegativeClickHandler={popupNegativeClickHandler}
            />
            {holdersInformation?.map((item, index) => (
              <TableResult
                key={index}
                data={item}
                editMode={editMode}
                clickUniqueCheckboxHandler={clickUniqueCheckboxHandler}
                selectedCheckboxes={selectedCheckboxes}
              />
            ))}
          </div>
        </div>
      </main>
    </div>
  );
};

export default ManagerRequestInclusion;
