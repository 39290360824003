import { Checkbox } from '@mui/material';
import { BiEdit } from 'react-icons/bi';
import { MdEditOff } from 'react-icons/md';
import { RiDeleteBin6Fill } from 'react-icons/ri';
import PopperDeleteConfirm from '../popperDeleteConfirmation';
import './style.scss';

const TableHeader = ({
  editMode,
  anchorElDelete,
  holdersInformation,
  editModeClickHandler,
  clickMasterCheckboxHandler,
  selectedCheckboxes,
  masterCheckboxChecked,
  clickDeleteRequestInclusionHandler,
  handleClose,
  handleDisplayMessage,
  popupPositiveClickHandler,
  popupNegativeClickHandler
}) => {
  return (
    <div className="manager-request-table-header">
      <div>
        {editMode === false ? (
          <BiEdit
            size={17}
            className="manager-request-table-header__button-edit"
            onClick={editModeClickHandler}
          />
        ) : (
          <Checkbox
            size="small"
            sx={{
              color: '#1E2552',
              '&.Mui-checked': {
                color: '#1E2552'
              },
              '&.MuiCheckbox-indeterminate': {
                color: '#1E2552'
              }
            }}
            onChange={clickMasterCheckboxHandler}
            indeterminate={
              selectedCheckboxes.length > 0 &&
              selectedCheckboxes.length < holdersInformation.length
            }
            checked={
              holdersInformation.length === selectedCheckboxes.length &&
              masterCheckboxChecked
            }
          />
        )}
      </div>
      <div className="manager-request-table-header__column-name">
        <span className="manager-request-table-header__span">Nome</span>
      </div>
      <div className="manager-request-table-header__column-name">
        <span className="manager-request-table-header__span">Produto</span>
      </div>
      <div className="manager-request-table-header__column-name">
        <span className="manager-request-table-header__span">Status</span>
      </div>
      <div className="manager-request-table-header__column-name">
        <span className="manager-request-table-header__span">Revisar</span>
      </div>
      <div className="manager-request-table-header__column-name">
        {editMode === true ? (
          <div className="manager-request-table-header-edit-buttons">
            <div>
              <MdEditOff
                className="manager-request-table-header-edit-buttons__button-edit"
                size={18}
                onClick={editModeClickHandler}
              />
            </div>
            <div>
              <RiDeleteBin6Fill
                className="manager-request-table-header-edit-buttons__delete-button"
                size={18}
                onClick={clickDeleteRequestInclusionHandler}
              />
            </div>
            <PopperDeleteConfirm
              anchorElDelete={anchorElDelete}
              handleClose={handleClose}
              handleDisplayMessage={handleDisplayMessage}
              selectedCheckboxes={selectedCheckboxes}
              popupPositiveClickHandler={popupPositiveClickHandler}
              popupNegativeClickHandler={popupNegativeClickHandler}
            />
          </div>
        ) : (
          <div></div>
        )}
      </div>
    </div>
  );
};

export default TableHeader;
