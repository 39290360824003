import React, { useState } from 'react';
import { CircularProgress } from '@mui/material';
import Checkbox from 'components/DS/Checkbox';
import Modal from 'components/DS/Modal';
import SelectStandard from 'apps/broker/components/Select';
import InputStandard from 'components/Input/InputStandard';
import FormNewBusiness from './FormNewBusiness';
import Button from 'components/DS/Button';
import './FormCreateBusiness.scss';

const funilEtapas = [
  {
    label: 'Não alocados',
    id: 5
  },
  {
    label: 'Estudo',
    id: 1
  },
  {
    label: 'Negociação',
    id: 2
  },
  {
    label: 'Proposta em análise',
    id: 3
  },
  {
    label: 'Implantação',
    id: 4
  }
];

const FormCreateBusiness = ({
  etiqueta,
  setEtiqueta,
  transitionLeave,
  setTransitionLeave,
  setOpenDrawer,
  tipo,
  setTipo,
  produto,
  setProduto,
  buscaCnpj,
  setBuscaCnpj,
  cnpj,
  setCnpj,
  estipulanteSelecionado,
  setEstipulanteSelecionado,
  cadastrado,
  setCadastrado,
  vidas,
  setVidas,
  nomeFantasia,
  setNomeFantasia,
  setContratoSelecionado,
  responsavelSelecionado,
  setResponsavelSelecionado,
  optionsResponsavel,
  estipulantes,
  dataContratos,
  contratoSelecionado,
  isDisabled,
  loading,
  confirmation,
  createNewNegociosHandler,
  estagio,
  setEstagio
}) => {
  console.log('nomeFantasia', nomeFantasia);
  const keyPressInputNumberHandler = (e) => {
    const oldValue = e.target.value;
    const newValue = oldValue?.replace(/[^0-9]/g, '');
    setVidas(newValue);
  };

  const handleClose = () => {
    setTransitionLeave(true);
    setTimeout(() => {
      setOpenDrawer(false);
    }, 200);
  };

  const handleCheckboxChange = () => {
    setBuscaCnpj(undefined);
    setCnpj('');
    setEstipulanteSelecionado(undefined);
    setCadastrado(!cadastrado);
  };

  return (
    <Modal
      title="Novo negócio"
      onClose={handleClose}
      isOpen={true}
      transitionLeave={transitionLeave}
    >
      <div className="form-create-business__container">
        <div className="form-create-business__left-column">
          <SelectStandard
            name="tipo"
            label="Tipo"
            options={[
              { value: 'renovacao', option: 'Renovação' },
              { value: 'novo', option: 'Novo' }
            ]}
            value={tipo}
            setValue={(object) => setTipo(object.value)}
          />
          <SelectStandard
            name="produto"
            label="Produto"
            options={[
              { value: 'saude', option: 'Saúde' },
              { value: 'dental', option: 'Dental' },
              { value: 'vida', option: 'Vida' }
            ]}
            value={produto}
            setValue={(object) => setProduto(object.value)}
          />
          {tipo === 'renovacao' && (
            <SelectStandard
              name="contrato"
              label="Contrato"
              options={dataContratos}
              value={contratoSelecionado}
              setValue={(object) => setContratoSelecionado(object.value)}
            />
          )}

          {tipo === 'renovacao' && (
            <InputStandard
              name="nomeFantasia"
              label="Nome fantasia"
              value={nomeFantasia}
              setValue={(e) => setNomeFantasia(e.target.value)}
            />
          )}

          {tipo === 'novo' && (
            <div className="form-create-business__checkbox-container">
              <Checkbox
                label="Já é cadastrado?"
                checked={cadastrado}
                onChange={handleCheckboxChange}
              />
            </div>
          )}
        </div>
        <div className="form-create-business__right-column">
          <SelectStandard
            name="responsaveis"
            label="Responsável"
            options={optionsResponsavel}
            value={responsavelSelecionado}
            setValue={(object) => setResponsavelSelecionado(object.value)}
          />

          <InputStandard
            name="vidas"
            label="Vidas"
            value={vidas}
            setValue={keyPressInputNumberHandler}
          />

          <SelectStandard
            name="etiqueta"
            label="Etiqueta"
            options={[
              { value: 'frio', option: 'Frio', color: '#6E89FF' },
              { value: 'morno', option: 'Morno', color: '#FCC400' },
              { value: 'quente', option: 'Quente', color: '#FF0909' }
            ]}
            value={etiqueta}
            setValue={(object) => setEtiqueta(object.value)}
            iconPosition="right"
          />

          {tipo === 'renovacao' && (
            <SelectStandard
              name="estipulante"
              label="Estipulante"
              options={estipulantes}
              value={estipulanteSelecionado}
              setValue={(object) => setEstipulanteSelecionado(object.value)}
            />
          )}
        </div>
      </div>

      <div className="form-create-business__funnel-steps">
        <h3>Etapa do funil</h3>
        <div className="funnel-steps__arrows">
          {funilEtapas.map((etapa, index) => (
            <div
              key={index}
              className={`funnel-step__arrow ${index === 0 ? 'first' : ''} ${
                etapa.id === estagio ? 'active' : ''
              }`}
              onClick={() => setEstagio(etapa.id)}
            />
          ))}
        </div>
        <p className="funnel-step__label">
          {funilEtapas.find((etapa) => etapa.id === estagio).label}
        </p>
      </div>

      <div className="form-create-business__container-dynamic">
        {tipo !== 'renovacao' && (
          <FormNewBusiness
            buscaCnpj={buscaCnpj}
            setBuscaCnpj={setBuscaCnpj}
            cnpj={cnpj}
            setCnpj={setCnpj}
            setEstipulanteSelecionado={setEstipulanteSelecionado}
            cadastrado={cadastrado}
            setCadastrado={setCadastrado}
            estipulantes={estipulantes}
            nomeFantasia={nomeFantasia}
            estipulanteSelecionado={estipulanteSelecionado}
            selectValueChangeHandler={setEstipulanteSelecionado}
            keyPressInputNomeFantasiaHandler={(e) =>
              setNomeFantasia(e.target.value)
            }
          />
        )}
      </div>

      <div className="form-create-business__button-container">
        <Button variant="default" onClick={handleClose}>
          Cancelar
        </Button>
        {!loading && (
          <Button
            isDisabled={isDisabled}
            onClick={isDisabled ? () => {} : createNewNegociosHandler}
          >
            Criar negócio
          </Button>
        )}

        {loading ? (
          <CircularProgress
            size={25}
            thickness={2}
            className="form-create-business__circular-spinner"
          />
        ) : null}

        {confirmation && (
          <span className="form-create-business__message-success">
            Novo negócio criado com sucesso
          </span>
        )}
      </div>
    </Modal>
  );
};

export default FormCreateBusiness;
