import styled from 'styled-components';
import colors from 'apps/broker/styles/colors';

export const Aside = styled.aside`

  display: flex;
  flex-direction: column;
  width: 80px;
  height: 100vh;
  background: ${colors.genoaBlue};

  .navFixed{
      position: fixed;
      background: ${colors.genoaBlue};
      width: inherit;
      height: 100%;
      display: inherit;
      flex-direction: inherit;
  }

  @media (max-height: 580px) {
    .navFixed {
      position: absolute;
      background: ${colors.genoaBlue};
      width: inherit;
      height: 250vh;
      display: inherit;
      flex-direction: inherit;
    }
  }

  @media (min-height: 580px) and (max-height: 650px) {
    .navFixed {
      position: absolute;
      background: ${colors.genoaBlue};
      width: inherit;
      height: 195vh;
      display: inherit;
      flex-direction: inherit;
    }
  }

  @media (min-height: 650px) and (max-height: 720px) {
    .navFixed {
      position: absolute;
      background: ${colors.genoaBlue};
      width: inherit;
      height: 172vh;
      display: inherit;
      flex-direction: inherit;
    }

    
  }

  .animeLeft {
      margin-top: 30px;
      opacity: 1;
  }

  ul {
      list-style:none;

      .li-logo{
          margin-bottom: 40px;
          img{
              width: 25px;
          }
      }
      li {
          text-align: center;
          margin-bottom: 25px;
          font-size: 1.6rem;
          position: relative;
          svg {
              color: #fff;
              transition: 0.2s;
          }
          svg:hover {
              font-size: 1.8rem;
          }
          a{
              display: flex;
              height: 35px;
              justify-content: center;
          }

          .MuiBadge-badge {
            background-color: ${colors.genoaPink};
            transform: scale(1) translate(70%, 45%);
            padding: 0 6px;
            text-align: center;
            font-size: 0.60rem;
            min-width: 18px;
            height: 18px;
            z-index: 1000;
          }

          .icon-container {
            display: flex;
            width: 29px;
            height: 44px;

            :hover {
              svg {
                font-size: 1.8rem;
              }
            }
          }

          .invisible {
            .MuiBadge-badge {
              background-color: ${colors.genoaPink};
              transform: scale(0) translate(70%, 45%);
            }
          }
        }
    }
    .money_icon {
      background-color: ${colors.white};
      color: ${colors.genoaBlue};
      border-radius: 35px;
      
      path:nth-child(2) {
        transform: translateX(0.5px);
      }

    }

    .negocio_sem_resp {
      position: relative;
      transform: translateY(-15px) translateX(10px);

      .MuiBadge-badge {
        background-color: #FFD600;
        color: #FFFFFF;
        font-weight: bold;
      }
    }

    .notificacao_atividades {
      transform: translateY(-4px) translateX(5px);
    }
  }

  .description {
      font-family: 'Avenir Next GEO W05 Regular';
      font-size: 0.9rem;
      padding: 3px 5px;
      position: absolute;
      height: max-content;
      width: max-content;
      top: 2px;
      left: 85px;
      background-color: ${colors.darkGray};
      color: #fff;
      display: flex;
      justify-content: center;
      align-items: center;
      opacity: 0.8;
      transition: opacity 0.5s;
      border-radius: 5px;
  }

  .off {
      opacity: 0;
      pointer-events: none;
  }


`;
