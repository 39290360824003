import { useState } from 'react';
import { Box, ClickAwayListener, Paper } from '@material-ui/core';
import { MdKeyboardArrowDown } from 'react-icons/md';
import { Popper } from '@mui/material';
import { usePostHog } from 'posthog-js/react';
import './styles.scss';

const SelectStandard = ({
  value,
  setValue,
  label,
  name,
  options,
  dropDownHeight,
  selectPlaceholder,
  disabled,
  posthogSelectClickHandlerEvent,
  posthogOptionClickHandlerEvent
}) => {
  const posthog = usePostHog();
  const [focused, setFocused] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  const optionClickHandler = (value, option) => {
    if (posthogOptionClickHandlerEvent) {
      posthog?.capture(posthogOptionClickHandlerEvent);
    }
    setValue({ name, value });
    setFocused(false);
  };

  const renderMaxDropdownheight = () => {
    return dropDownHeight || '200px';
  };

  const renderOptions = () => {
    return options?.map((item) => (
      <span
        key={item.value}
        className="select-standard__option"
        onClick={() => optionClickHandler(item.value, item.option)}
      >
        {item.color && (
          <div
            style={{ width: 20, height: 5, backgroundColor: item.color }}
          ></div>
        )}
        <div dangerouslySetInnerHTML={{ __html: item.option }} />
      </span>
    ));
  };

  const renderOptionSelected = () => {
    const optionSelected = options?.find((item) => item.value === value);

    return <div dangerouslySetInnerHTML={{ __html: optionSelected?.option }} />;
  };

  const renderSelectPlaceholder = () => {
    return selectPlaceholder || 'Selecione';
  };

  return (
    <ClickAwayListener onClickAway={() => setFocused(false)}>
      <div className="select-standard">
        <label
          className={`select-standard__label ${
            disabled ? 'select-standard__label--disabled' : ''
          }`}
        >
          {label}
        </label>
        <div
          className={`select-standard__trigger ${
            disabled ? 'select-standard__trigger--disabled' : ''
          }`}
          onClick={(e) => {
            if (!disabled) {
              if (posthogSelectClickHandlerEvent) {
                posthog?.capture(posthogSelectClickHandlerEvent);
              }
              setFocused(true);
              setAnchorEl(e.currentTarget);
            }
          }}
          tabIndex="0"
        >
          <span className="select-standard__value">
            {value ? renderOptionSelected() : renderSelectPlaceholder()}
          </span>
          <MdKeyboardArrowDown
            className={`select-standard__icon ${
              focused ? 'select-standard__icon--open' : ''
            }`}
          />
        </div>
        {!disabled && options?.length ? (
          <Popper
            open={focused}
            anchorEl={anchorEl}
            placement="bottom-start"
            disablePortal
            className="select-standard__popper"
          >
            <Box>
              <Paper elevation={8}>
                <div
                  className="select-standard__options-wrapper"
                  style={{
                    width: anchorEl?.offsetWidth,
                    maxHeight: renderMaxDropdownheight()
                  }}
                >
                  {renderOptions()}
                </div>
              </Paper>
            </Box>
          </Popper>
        ) : null}
      </div>
    </ClickAwayListener>
  );
};

export default SelectStandard;
