import { useState, useMemo, useEffect } from 'react';
import { CardAtividadeWrapper } from './styles';
import { GiHealthNormal } from 'react-icons/gi';
import { FaTooth } from 'react-icons/fa';
import { Checkbox, ClickAwayListener } from '@mui/material';
import {
  BsArrowLeftCircleFill,
  BsArrowRightCircleFill,
  BsCheck,
  BsCircle,
  BsCircleFill,
  BsFillCheckCircleFill
} from 'react-icons/bs';
import { TbEyeglass } from 'react-icons/tb';
import moment from 'moment';
import { toast } from 'react-hot-toast';
import { AiFillWarning } from 'react-icons/ai';
import { MdClose } from 'react-icons/md';
import ReactDatePicker from 'react-datepicker';
import services from 'apps/broker/services';

const CardAtividade = ({
  atividadeInfo,
  negociosInfo,
  setNegocioSelected,
  setNewAtividadeNegocio,
  corretores,
  getNegociosData,
  setEditAtividade,
  editAtividade
}) => {
  const [checkboxChecked, setCheckboxChecked] = useState(false);
  const [defaultDescricao, setDefaultDescricao] = useState('');
  const [editedDescricao, setEditedDescricao] = useState('');
  const [editedDate, setEditedDate] = useState('');
  const [openEditDate, setOpenEditDate] = useState(false);
  const [prioridade, setPrioridade] = useState(atividadeInfo.prioridade);

  const selectNegocioHandler = () => {
    setNegocioSelected(atividadeInfo?.negocio_id);
  };
  const updatePrioridade = async (newPrioridade) => {
    setPrioridade(newPrioridade);

    const body = {
      atividade_id: atividadeInfo.atividade_id,
      prioridade: newPrioridade
    };

    const response = await services.activities.updatePrioridadeByActivityId(
      body
    );

    if (response.status === 200) {
      getNegociosData();
      toast.dismiss();
      toast.success('Prioridade da atividade foi atualizada!', {
        duration: 2500
      });
    } else {
      toast.error('Algo deu errado, tente novamente mais tarde!', {
        duration: 2500
      });
    }
  };

  const iconAtividadeHandler = (item) => {
    const timeStampInicial = new Date().setHours(0, 0, 0, 0);

    const timeStampFinal = new Date().setHours(23, 59, 59, 999);

    const dataAgendada = new Date(item.data_agendada).getTime();

    if (dataAgendada >= timeStampInicial && dataAgendada < timeStampFinal) {
      return 'green';
    }
    if (dataAgendada < timeStampInicial) {
      return 'red';
    }
    if (dataAgendada > timeStampFinal) {
      return 'grey';
    }
  };

  const renderAtivityCircle = (item) => {
    let color;

    if (item.usuario_id) {
      if (item?.data_fechamento === null) {
        color = iconAtividadeHandler(item);
      } else {
        color = 'green';
      }
    }

    return (
      <>
        <div className="icon-alert">
          {color === 'yellow' ? (
            <AiFillWarning className={`status-yellow-circle-icon`} />
          ) : color === 'red' ? (
            <BsArrowLeftCircleFill className={`status-red-circle-icon`} />
          ) : color === 'grey' ? (
            <BsArrowRightCircleFill className={`status-grey-circle-icon`} />
          ) : (
            <BsCircleFill className={`status-green-circle-icon`} />
          )}
        </div>
        <div className="plus" onClick={() => setNewAtividadeNegocio(item)}>
          +
        </div>
      </>
    );
  };

  const updateDescricaoAtividade = async () => {
    const body = {
      descricao: editedDescricao,
      atividade_id: atividadeInfo.atividade_id
    };

    const response = await services.activities.updateDescriptionByActivityId(
      body
    );

    if (response.status === 200) {
      getNegociosData();
      setEditAtividade(0);
      toast.dismiss();
      toast.success('A descrição da atividade foi atualizada!', {
        duration: 2500
      });
    } else {
      toast.error('Algo deu errado, tente novamente mais tarde!', {
        duration: 2500
      });
    }
  };

  const updateDateAtividade = async () => {
    const body = {
      data_agendada: new Date(editedDate),
      atividade_id: atividadeInfo.atividade_id
    };

    const response = await services.activities.updateDateByActivityId(body);

    if (response.status === 200) {
      getNegociosData();
      setOpenEditDate(false);
      toast.dismiss();
      toast.success('Data atualizada com sucesso!', {
        duration: 2500
      });
    } else {
      toast.dismiss();
      toast.error('Algo deu errado, tente novamente mais tarde!');
    }
  };

  const changeAtividadeStatusHandler = async (e) => {
    const checked = e.target.checked;

    // verifica se possui alguma atividade em aberto nesse negócio ou se essa era a ultima
    const atividades = negociosInfo.filter(
      (item) => item.id === atividadeInfo.negocio_id
    )[0].atividades;
    const atividadesAbertas = atividades.filter(
      (item) => item.data_fechamento === null
    );

    setCheckboxChecked(checked);
    const body = {
      atividade_id: atividadeInfo.atividade_id,
      newData: {
        closed_at: checked ? moment().format('YYYY-MM-DD HH:mm:ss') : null
      }
    };

    const response = await services.activities.updateStatusClosedByActivityId(
      body
    );

    if (response.status === 200) {
      getNegociosData();
      toast.dismiss();
      toast.success('A atividade foi finalizada com sucesso!', {
        duration: 2500
      });

      if (atividadesAbertas.length === 1) {
        toast.dismiss();
        setNewAtividadeNegocio(atividadesAbertas[0]);
      }
    } else {
      toast.error('Algo deu errado, tente novamente mais tarde!', {
        duration: 2500
      });
    }
  };

  const updateResponsavelAtividade = async (e) => {
    const integerId = Number(e.target.value);

    const body = {
      atividade_id: atividadeInfo.atividade_id,
      newData: {
        usuario_id: integerId ? integerId : null
      }
    };

    const response = await services.business.updateResponsableByActivityId(
      body
    );
    if (response.status === 200) {
      getNegociosData();
      toast.dismiss();
      toast.success('O usuário responsável pela atividade foi atualizado!', {
        duration: 2500
      });
    } else {
      toast.error('Algo deu errado, tente novamente mais tarde!', {
        duration: 2500
      });
    }
  };

  const optionsCorretores = () => {
    return corretores?.map((item) => (
      <option className="options" value={item.usuario_id}>
        {`${item.nome}`}
      </option>
    ));
  };

  const renderEstipulanteOuLead = (atividadeInfo) => {
    const negocio = negociosInfo?.filter(
      (item) => item.id === atividadeInfo.negocio_id
    );

    if (negocio[0].estipulante_id) {
      if (negocio[0].estipulante_nome_fantasia !== null) {
        return negocio[0].estipulante_nome_fantasia?.nome_fantasia;
      } else {
        return negocio[0].estipulante_razao_social;
      }
    } else if (negocio[0].lead_potencial_id) {
      if (negocio[0].lead_potencial_nome_fantasia !== null) {
        if (negocio[0].lead_potencial_nome_fantasia?.nome_fantasia !== null) {
          return negocio[0].lead_potencial_nome_fantasia?.nome_fantasia;
        } else {
          return negocio[0].lead_razao_social;
        }
      } else {
        return negocio[0].lead_razao_social;
      }
    }
  };

  useMemo(() => {
    setCheckboxChecked(atividadeInfo?.data_fechamento);
  }, [atividadeInfo]);

  useEffect(() => {
    setDefaultDescricao(atividadeInfo.descricao);
    setEditedDescricao(atividadeInfo?.descricao);
  }, [atividadeInfo]);

  useEffect(() => {
    if (openEditDate) {
      updateDateAtividade();
    }
  }, [editedDate]);

  const PrioridadeIndicator = ({ prioridade }) => {
    const getValue = (prioridade) => {
      switch (prioridade) {
        case 1:
          return {
            color: '#4CAF50',
            text: 'Prioridade Baixa'
          };
        case 2:
          return {
            color: '#eaaf00',
            text: 'Prioridade Média'
          };
        case 3:
          return {
            color: '#F44336',
            text: 'Prioridade Alta'
          };
        default:
          return {
            color: '#4CAF50',
            text: 'Prioridade Baixa'
          };
      }
    };

    return (
      <select
        title={getValue(prioridade).text}
        value={prioridade}
        onChange={(e) => updatePrioridade(Number(e.target.value))}
        style={{
          backgroundColor: getValue(prioridade).color,
          color: '#fff',
          fontWeight: 'bold',
          border: 'none',
          padding: '3px 6px',
          borderRadius: '3px'
        }}
      >
        <option value={1}>Baixa</option>
        <option value={2}>Média</option>
        <option value={3}>Alta</option>
      </select>
    );
  };
  return (
    <CardAtividadeWrapper>
      <td>
        <Checkbox
          checked={checkboxChecked}
          icon={<BsCircle />}
          checkedIcon={<BsFillCheckCircleFill />}
          onChange={changeAtividadeStatusHandler}
        />
      </td>
      <td>
        {editAtividade === atividadeInfo.atividade_id ? (
          <div className="input_descricao_container">
            <input
              type="text"
              autoFocus="true"
              className="input_descricao_tabela"
              value={editedDescricao}
              onChange={({ target }) => {
                setEditedDescricao(target.value);
              }}
            />
            <div className="input_descricao_btns">
              <button
                className="btn_check"
                onClick={() => {
                  if (editedDescricao !== defaultDescricao) {
                    updateDescricaoAtividade();
                  }
                }}
              >
                <BsCheck />
              </button>
              <button
                className="btn_close"
                onClick={() => {
                  setEditAtividade(0);
                  setEditedDescricao(atividadeInfo?.descricao);
                }}
              >
                <MdClose />
              </button>
            </div>
          </div>
        ) : (
          <span onClick={() => setEditAtividade(atividadeInfo.atividade_id)}>
            {editedDescricao}
          </span>
        )}
      </td>
      <td>
        {openEditDate ? (
          <ClickAwayListener onClickAway={() => setOpenEditDate(false)}>
            <div>
              <ReactDatePicker
                className={`custom_datepicker ${editedDate ? 'active' : ''}`}
                autoFocus="true"
                selected={new Date(atividadeInfo?.data_agendada)}
                locale={'ptBR'}
                minDate={new Date()}
                onChange={(date) => setEditedDate(date)}
                onKeyDown={(e) => {
                  if (e?.key === 'Enter') {
                    updateDateAtividade();
                  }
                }}
                dateFormat="dd/MM/yyyy"
              />
            </div>
          </ClickAwayListener>
        ) : (
          <span onClick={() => setOpenEditDate(true)}>
            {moment(atividadeInfo?.data_agendada).format('DD/MM/YYYY')}
          </span>
        )}
      </td>
      <td>{moment(atividadeInfo?.created_at).fromNow()}</td>
      <td className="truncate" title={renderEstipulanteOuLead(atividadeInfo)}>
        {renderEstipulanteOuLead(atividadeInfo)}
      </td>
      <td>
        {atividadeInfo?.produto === 'Odonto' && <FaTooth size={11} />}
        {atividadeInfo?.produto === 'Saúde' && <GiHealthNormal size={13} />}
      </td>
      <td>Até {atividadeInfo?.vidas} vidas</td>
      <td>{atividadeInfo?.estagio}</td>
      <td>
        <PrioridadeIndicator prioridade={atividadeInfo?.prioridade} />
      </td>
      <td>
        <select
          className="select select__trigger"
          value={(atividadeInfo.usuario_id ??= 0)}
          onChange={(e) => updateResponsavelAtividade(e)}
        >
          <option className="options" value={0}>
            Selecione
          </option>
          {optionsCorretores()}
        </select>
      </td>
      <td className="atividade">{renderAtivityCircle(atividadeInfo)}</td>
      <td>
        <button className="details_button" onClick={selectNegocioHandler}>
          <TbEyeglass />
        </button>
      </td>
    </CardAtividadeWrapper>
  );
};

export default CardAtividade;
