import { useState, useEffect } from 'react';
import Button from 'components/DS/Button';
import SelectStandard from '../../../../components/Select';
import Modal from 'components/DS/Modal';
import { ThemeProvider, createTheme } from '@mui/material';
import ReactDatePicker from 'react-datepicker';
import { toast } from 'react-hot-toast';
import services from 'apps/broker/services';
import './CreateNewActivity.scss';

const theme = createTheme({
  palette: {
    genoaBlue: {
      main: '#456AED'
    },
    darkGenoaBlue: {
      main: '#1E2552'
    }
  }
});

const DrawerNovaAtividade = ({
  setNewActivity,
  corretores,
  isNewActivity,
  getNegociosData,
  setNewAtividadeNegocio,
  negociosInfo
}) => {
  const [transitionLeave, setTransitionLeave] = useState(false);
  const [optionsResponsavel, setOptionsResponsavel] = useState([]);
  const [responsavelSelecionado, setResponsavelSelecionado] = useState('0');
  const [dataAtividade, setDataAtividade] = useState(new Date());
  const [descricaoAtividade, setDescricaoAtividade] = useState('');
  const [isDisabled, setIsDisabled] = useState(true);
  const [activeBusiness, setActiveBusiness] = useState();
  const [prioridade, setPrioridade] = useState('1');

  const getNegocios = () => {
    const tipo = { 1: true, 2: true };
    const status = {
      1: true,
      2: false,
      3: false,
      4: false
    };

    const produto = {
      1: true,
      2: true,
      3: true
    };

    const dadosFiltrado = negociosInfo
      ?.filter((item) => tipo[item.tipo_id])
      .filter((item) => status[item.status_id])
      .filter((item) => produto[item.produto_id]);

    return dadosFiltrado;
  };

  const isToSelectBusiness = typeof isNewActivity === 'boolean';

  const business = isToSelectBusiness ? getNegocios() : [];

  const businessOptions = business?.map((item) => ({
    value: item.id,
    option: `${item.estipulante_razao_social} <span class="status">${item.estagio}</span>`
  }));

  const criarAtividadeHandler = async () => {
    const body = {
      negocio_id: isToSelectBusiness ? activeBusiness : isNewActivity?.id,
      descricao: descricaoAtividade,
      usuario_id: responsavelSelecionado,
      data_agendada: dataAtividade,
      prioridade: parseInt(prioridade)
    };

    const response = await services.activities.createByBusinessId(body);

    if (response.status === 201) {
      toast.dismiss();
      toast.success('Atividade adicionada com sucesso!');
      getNegociosData();
      setNewAtividadeNegocio(false);
    } else {
      toast.dismiss();
      toast.error('Algo deu errado, tente novamente mais tarde!');
    }
  };

  const selectValueChangeHandler = (object) => {
    setResponsavelSelecionado(object.value);
  };

  useEffect(() => {
    const naoAlocado = {
      value: '0',
      option: 'Selecione'
    };
    if (corretores?.length) {
      const responsaveis = corretores?.map((item) => ({
        value: item.usuario_id,
        option: `${item.nome}`
      }));

      setOptionsResponsavel([naoAlocado, ...responsaveis]);
    }
  }, [corretores]);

  useEffect(() => {
    if (responsavelSelecionado !== '0' && dataAtividade && descricaoAtividade) {
      setIsDisabled(false);
    } else {
      setIsDisabled(true);
    }
  }, [responsavelSelecionado, dataAtividade, descricaoAtividade]);

  return (
    <Modal
      title="Nova atividade"
      onClose={() => setNewActivity(false)}
      isOpen={true}
      transitionLeave={transitionLeave}
    >
      <div className="drawer-nova-atividade__form-container">
        <div className="drawer-nova-atividade__form-option">
          <SelectStandard
            label={'Responsável'}
            name={'responsaveis'}
            options={optionsResponsavel}
            value={responsavelSelecionado}
            setValue={selectValueChangeHandler}
          />
        </div>
        {isToSelectBusiness && (
          <div className="drawer-nova-atividade__form-option">
            <SelectStandard
              label={'Negócio'}
              name={'negocios'}
              options={businessOptions}
              value={activeBusiness}
              setValue={({ value }) => setActiveBusiness(value)}
            />
          </div>
        )}
        <div className="drawer-nova-atividade__form-option">
          <div className="drawer-nova-atividade__form-option-title">
            Data Agendada
          </div>
          <ThemeProvider theme={theme}>
            <ReactDatePicker
              className={`drawer-nova-atividade__custom-datepicker ${
                dataAtividade ? 'active' : ''
              }`}
              selected={dataAtividade}
              locale="ptBR"
              minDate={new Date()}
              onChange={(data) => setDataAtividade(data)}
              dateFormat="dd/MM/yyyy"
            />
          </ThemeProvider>
        </div>
        <div className="drawer-nova-atividade__form-option">
          <div className="drawer-nova-atividade__textarea-container">
            <div className="drawer-nova-atividade__form-option-title">
              Descrição da atividade
            </div>

            <textarea
              rows={2}
              maxLength={255}
              value={descricaoAtividade}
              onChange={(e) => setDescricaoAtividade(e.target.value)}
            />
          </div>
        </div>
        <div className="drawer-nova-atividade__form-option">
          <SelectStandard
            label={'Prioridade'}
            name={'responsaveis'}
            options={[
              {
                value: '1',
                option: 'Baixa'
              },
              {
                value: '2',
                option: 'Média'
              },
              {
                value: '3',
                option: 'Alta'
              }
            ]}
            value={prioridade}
            setValue={({ value }) => setPrioridade(value)}
          />
        </div>
        <div className="drawer-nova-atividade__button-container">
          <Button variant="default" onClick={() => setNewActivity(false)}>
            Cancelar
          </Button>
          <Button
            isDisabled={isDisabled}
            onClick={isDisabled ? () => {} : criarAtividadeHandler}
          >
            Criar atividade
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default DrawerNovaAtividade;
